import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { DoctorsComponent } from './pages/doctors/doctors.component';
import { PatientsComponent } from './pages/patients/patients.component';
import { RightsComponent } from './pages/rights/rights.component';
import { RolesComponent } from './pages/roles/roles.component';
import { LookupsComponent } from './pages/lookups/lookups.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';
import { PostsComponent } from './pages/posts/posts.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/doctors', pathMatch: 'full' },
  { path: 'doctors', component: DoctorsComponent, canActivate:[AuthGuard] },
  { path: 'patients', component: PatientsComponent , canActivate:[AuthGuard] },
  { path: 'rights', component: RightsComponent, canActivate:[AuthGuard]  },
  { path: 'roles', component: RolesComponent , canActivate:[AuthGuard] },
  { path: 'lookups', component: LookupsComponent , canActivate:[AuthGuard] },
  { path: 'blogs', component: BlogsComponent , canActivate:[AuthGuard] },
  { path: 'posts', component: PostsComponent , canActivate:[AuthGuard] },
  { path: 'surveys', component: SurveysComponent , canActivate:[AuthGuard] },
  { path: 'auth/login', component: LoginComponent },
  { path: 'dashboard', component: ECommerceComponent , canActivate:[AuthGuard] },
  { path: 'iot-dashboard', component: DashboardComponent , canActivate:[AuthGuard] },
  { path: '**', component: NotFoundComponent },

];
const config: ExtraOptions = {
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
