import {
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import {
  CommonModule,
  DOCUMENT,
} from '@angular/common';

import {
  NbThemeService,
} from './services/theme.service';
import {
  NbSpinnerService,
} from './services/spinner.service';
import {
  NbJSThemeOptions,
} from './services/js-themes/theme.options';
import {
  BUILT_IN_THEMES,
  NbJSThemesRegistry,
} from './services/js-themes-registry.service';
import {
  DEFAULT_MEDIA_BREAKPOINTS,
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
} from './services/breakpoints.service';
import {
  NbLayoutDirectionService,
  NbLayoutDirection,
  NB_LAYOUT_DIRECTION,
} from './services/direction.service';
import {
  NbLayoutScrollService,
} from './services/scroll.service';
import {
  NbLayoutRulerService,
} from './services/ruler.service';

import {
  NbOverlayModule,
} from './components/cdk/overlay/overlay.module';
import {
  NbActionsModule,
} from './components/actions/actions.module';
import {
  NbLayoutModule,
} from './components/layout/layout.module';
import {
  NbMenuModule,
} from './components/menu/menu.module';
import {
  NbSearchModule,
} from './components/search/search.module';
import {
  NbSidebarModule,
} from './components/sidebar/sidebar.module';
import {
  NbUserModule,
} from './components/user/user.module';
import {
  NbContextMenuModule,
} from './components/context-menu/context-menu.module';
import {
  NbButtonModule,
} from './components/button/button.module';
import {
  NbSelectModule,
} from './components/select/select.module';
import {
  NbIconModule,
} from './components/icon/icon.module';
import {
  NbSecurityModule,
} from '../security/security.module';

// import { NbActionsModule } from './chat/chat.module';
// import { NbActionsModule } from './datepicker/datepicker.module';
// import { NbActionsModule } from './dialog/dialog.module';
// import { NbActionsModule } from './toastr/toastr.module';
// import { NbActionsModule } from './window/window.module';
// import { NbActionsModule } from './input/input.module';
// import { NbActionsModule } from './checkbox/checkbox.module';
// import { NbActionsModule } from './card/card.module';
// import { NbActionsModule } from './alert/alert.module';
// import { NbActionsModule } from './tabset/tabset.module';
// import { NbActionsModule } from './radio/radio.module';
// import { NbActionsModule } from './list/list.module';
// import { NbActionsModule } from './progress-bar/progress-bar.module';
// import { NbActionsModule } from './spinner/spinner.module';
// import { NbActionsModule } from './calendar/calendar.module';
// import { NbActionsModule } from './calendar-kit/calendar-kit.module';
// import { NbActionsModule } from './calendar/calendar-range.module';
// import { NbActionsModule } from './stepper/stepper.module';
// import { NbActionsModule } from './accordion/accordion.module';
// import { NbActionsModule } from './route-tabset/route-tabset.module';
// import { NbActionsModule } from './popover/popover.module';
// import { NbActionsModule } from './tooltip/tooltip.module';
// import { NbActionsModule } from './tree-grid/tree-grid.module';




import {
  CapitalizePipe,
} from './pipes/capitalize.pipe';
import {
  PluralPipe,
} from './pipes/plural.pipe';
import {
  RoundPipe,
} from './pipes/round.pipe';
import {
  TimingPipe,
} from './pipes/timing.pipe';
import {
  NumberWithCommasPipe,
} from './pipes/number-with-commas.pipe';

import {
  HeaderComponent,
} from './components/header/header.component';
import {
  FooterComponent,
} from './components/footer/footer.component';
import {
  SearchInputComponent,
} from './components/search-input/search-input.component';
import {
  TinyMCEComponent,
} from './components/tiny-mce/tiny-mce.component';

import {
  NgdEvaComponent,
} from './components/eva/eva.component';

// import {NbButtonComponent} from './components/button/button.component';
// import {NbAccordionItemBodyComponent} from './components/accordion/accordion-item-body.component';
// import {NbAccordionItemHeaderComponent} from './components/accordion/accordion-item-header.component';
// import {NbAccordionItemComponent} from './components/accordion/accordion-item.component';
// import {NbAccordionComponent} from './components/accordion/accordion.component';
// import {NbActionsComponent} from './components/actions/actions.component';
// import {NbAlertComponent} from './components/alert/alert.component';
// import {NbBadgeComponent} from './components/badge/badge.component';
// import {NbCalendarRangeComponent} from './components/calendar/calendar-range.component';
// import {NbCalendarComponent} from './components/calendar/calendar.component';
// import {NbMenuComponent} from './components/menu/menu.component';

import {
  AuthOneColumnLayoutComponent,
} from './components/auth-one-column/auth-one-column.layout';
import {
  AuthTwoColumnsLayoutComponent,
} from './components/auth-two-columns/auth-two-columns.layout';
import {
  AuthThreeColumnsLayoutComponent,
} from './components/auth-three-columns/auth-three-columns.layout';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  AuthOneColumnLayoutComponent,
  AuthTwoColumnsLayoutComponent,
  AuthThreeColumnsLayoutComponent,
  NgdEvaComponent,
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];


import {
  NB_BUILT_IN_JS_THEMES,
  NB_MEDIA_BREAKPOINTS,
  NbThemeOptions,
  NB_THEME_OPTIONS,
  NB_JS_THEMES,
  NB_DOCUMENT,
  NB_WINDOW,
} from './ngx-common.options';

import {
  DEFAULT_THEME,
} from './styles/theme.default';

export function nbWindowFactory() {
  return window;
}

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
  providers: [
    {
      provide: NB_THEME_OPTIONS,
      useValue: {
        name: 'default',
      },
    },
    {
      provide: NB_BUILT_IN_JS_THEMES,
      useValue: BUILT_IN_THEMES,
    },
    {
      provide: NB_JS_THEMES,
      useValue: [DEFAULT_THEME],
    },
    {
      provide: NB_MEDIA_BREAKPOINTS,
      useValue: DEFAULT_MEDIA_BREAKPOINTS,
    },
    {
      provide: NB_WINDOW,
      useFactory: nbWindowFactory,
    },
    {
      provide: NB_DOCUMENT,
      useExisting: DOCUMENT,
    },
    NbJSThemesRegistry,
    NbThemeService,
    NbMediaBreakpointsService,
    NbSpinnerService,
    {
      provide: NB_LAYOUT_DIRECTION,
      useValue: NbLayoutDirection.LTR,
    },
    NbLayoutDirectionService,
    NbLayoutScrollService,
    NbLayoutRulerService,
    ...NbOverlayModule.forRoot().providers,
  ],

})
export class NgxCommonModule {
}


