import { Injectable, NgZone, Inject } from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { NbPlatform } from '../overlay/mapping';
import { NbLayoutScrollService } from '../../../services/scroll.service';

@Injectable()
export class NbScrollDispatcherAdapter extends ScrollDispatcher {
  constructor(ngZone: NgZone, platform: NbPlatform, protected scrollService: NbLayoutScrollService, @Inject(DOCUMENT) document: any) {
    super(ngZone, platform,document);
  }

  scrolled(auditTimeInMs?: number): Observable<CdkScrollable | void> {
    return this.scrollService.onScroll();
  }
}

