import { Component, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbDialogService,
} from '../../common/public_api';
import { MENU_ITEMS } from '../../pages-menu';
import { DoctorService, IDoctorProfile, IPublishDoctorProfileRequest } from '../../auth/services/doctor.service';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'ngx-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss'],
})
export class DoctorsComponent implements OnInit {

  private menu = MENU_ITEMS;
  public defaultColumns = [
    'Name',
    'Licence/PMDC Number',
    'Clinic City',
    'Clinic Phone #',
    'Clinic Mobile #',
    'Created At',
    'Status',
    'Action',
    'View'
  ];

  public doctors: IDoctorProfile[] = [];
  public copyDoctors: IDoctorProfile[] = [];
  public loading = false;
  public searchInput: string = '';

  constructor(
    private dialogService: NbDialogService,
    private ToastService: NbToastrService,
    private doctorService: DoctorService,
  ) { }

  public ngOnInit() {
    this.populateDoctors();
  }

  private populateDoctors() {
    this.loading = true;
    this.doctorService.getDoctorsList()
      .then((response: any) => {
        this.doctors = response.doctors;
        this.copyDoctors = response.doctors;
      })
      .catch(() => this.ToastService.danger('Failed to get Doctors List'))
      .finally(() => this.loading = false);
  }

  public publishDoctor(doctor: IDoctorProfile) {
    this.loading = true;
    const request: IPublishDoctorProfileRequest = {
      identityId: doctor.identityID,
      isPublish: doctor.isPublish ? doctor.isPublish : false,
      isAdmin: true,
    };

    this.doctorService.publish(request)
      .then(() => {
        this.ToastService.success('Updated Successfully');
        this.populateDoctors();
      })
      .catch(() => this.ToastService.danger('Updated Failed'))
      .finally(() => this.loading = false);
  }

  public clear() {
    this.searchInput = '';
    this.doctors = this.copyDoctors;
  }

  public search() {
    this.doctors = this.copyDoctors
      .filter(doc => doc.title.toLowerCase().startsWith(this.searchInput.toLowerCase())
        || doc.title.toLowerCase().endsWith(this.searchInput.toLowerCase()));
  }
  viewDoctorProfile(doctorId: string) {
    const profileUrl = `${environment.apiUrl}/doctorProfile/${doctorId}`
    window.open(profileUrl, '_blank')
  }
}
