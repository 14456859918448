import { Component, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbDialogService,
} from '../../common/public_api';
import { MENU_ITEMS } from '../../pages-menu';
import { BlogService, IBlog, IPublishBlogRequest } from '../../auth/services/blog.service';
import { ILookupList, LookupService, ILookupListEntry } from '../../auth/services/lookup.service';
import * as _ from 'lodash';

@Component({
  selector: 'ngx-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {

  private menu = MENU_ITEMS;
  public lookups: ILookupList[] = [];
  public defaultColumns = [
    'Blog Id',
    'Title',
    'Author Name',
    'Category',
    'Publish Date',
    'Status',
    'Action',
  ];

  public blogs: IBlog[];
  public copyBlogs: IBlog[];
  public loading = false;
  public searchTitle: string = '';
  public searchAuthor: string = '';
  public searchDate: string = '';

  public constructor(
    private dialogService: NbDialogService,
    private ToastService: NbToastrService,
    private blogService: BlogService,
    public LookupsService: LookupService
  ) { }

  public ngOnInit() {
    this.loading = true;
    this.populatePatients();
    this.populateLookups();
  }

  private populatePatients() {
    this.blogService.getBlogs()
      .then((response: any) => {
        this.loading = false;
        this.blogs = response.items;
        this.copyBlogs = response.items;
      })
      .catch(() => {
        this.loading = false;
        this.ToastService.danger('Failed to get Blogs List');
      });
  }

  private populateLookups() {
    this.LookupsService.get()
      .then(response => this.lookups = response)
      .catch(() => this.ToastService.danger('Failed to get Lookup list.'));
  }

  public publishBlog(blog: IBlog) {
    this.loading = true;

    const request: IPublishBlogRequest = {
      blogID: blog.blogID,
      enabled: blog.enabled ? blog.enabled : false,
      isAdmin: true,
    };

    this.blogService.publish(request)
      .then(response => {
        this.loading = false;
        this.blogs.find(x => x.blogID === blog.blogID).enabled = response.enabled;
        this.ToastService.success('Updated Successfully');
      })
      .catch(() => {
        this.loading = false;
        this.ToastService.danger('Updated Failed');
      });
  }

  public getCategoryNamyByLookup(lookupId): string {
    if (_.isEmpty(this.lookups) || _.isNil(lookupId)) {
      return null;
    }

    const categories: any = this.lookups.find(item => item.name === 'Category').listEntries
    const category = categories.find(state => state.lookupIndex === lookupId)

    if(_.isNil(category)) {
      return null;
    }

    return category.name;
  }

  public clear() {
    this.searchTitle = '';
    this.searchAuthor = '';
    this.searchDate = '';
    this.blogs = this.copyBlogs;
  }

  public search() {
    const searchDateFormatted = this.searchDate ? this.formatDate(this.searchDate) : '';

    this.blogs = this.copyBlogs.filter(blog => {
      const titleMatches = blog.title.toLowerCase().startsWith(this.searchTitle.toLowerCase()) ||
                           blog.title.toLowerCase().endsWith(this.searchTitle.toLowerCase());

      const authorMatches = this.searchAuthor ? blog.authorName.toLowerCase().includes(this.searchAuthor.toLowerCase()) : true;

      const dateMatches = this.searchDate ?
        this.formatDate(blog.publishDate) === searchDateFormatted :
        true;

      return titleMatches && authorMatches && dateMatches;
    });
  }

  private formatDate(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
}
