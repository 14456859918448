import { Component, OnInit } from '@angular/core';
import { MENU_ITEMS } from '../../pages-menu';
import { NbDialogService, NbToastrService } from '../../common/public_api';
import { AddLookupModalComponent } from './add-lookup-modal/add-lookup-modal.component';
import { LookupService, ILookupList, ILookupListEntry } from '../.././auth/services/lookup.service';

@Component({
  selector: 'ngx-lookups',
  templateUrl: './lookups.component.html',
  styleUrls: ['./lookups.component.scss'],
})
export class LookupsComponent implements OnInit {

  private menu = MENU_ITEMS;
  public defaultColumns = [ 'name', 'description', 'action' ];
  public searchInput: string;
  public loading: boolean = false;
  public lookups: ILookupList[];

  public constructor(
    private dialogService: NbDialogService,
    public ToastService: NbToastrService,
    public LookupsService: LookupService) {
  }

  public ngOnInit() {
    this.populateLookups();
  }

  private populateLookups() {
    this.lookups = [];
    this.loading = true;
    this.LookupsService.get()
      .then(response => {
        this.lookups = response;
        this.loading = false;
      })
      .catch(error => {
        this.ToastService.danger('Failed to get Lookup list.')
        this.loading = false;
      });
  }

  public addLookup(lookupType: string) {
    this.dialogService.open(AddLookupModalComponent, {
      context: {
        lookupTypes: this.lookups.map(x => {
          return x.name;
        }),
        lookupType: lookupType,
      },
    }).onClose.subscribe(() => this.populateLookups());
  }

  public editLookup(lookupType: string, lookup: ILookupListEntry) {
    this.dialogService.open(AddLookupModalComponent, {
      context: {
        lookupTypes: this.lookups.map(x => {
          return x.name;
        }),
        lookupType: lookupType,
        lookup: lookup,
        isEditing: true,
      },
    }).onClose.subscribe(() => this.populateLookups());
  }

  public deleteLookup(lookupType: string, lookupIndex: number) {
    this.LookupsService.delete(lookupType, lookupIndex)
      .then(success => {
        this.ToastService.success('Delete Successful!');
        this.populateLookups();
      })
      .catch(error => this.ToastService.danger('Delete Failed!'));
  }

  public search() {
    if (this.searchInput) {
      this.lookups = this.lookups.filter(row => row.name.toLowerCase().includes(this.searchInput.toLowerCase()));
    }
    else {
      this.populateLookups();
    }
  }

  public clear() {
    this.searchInput = '';
    this.populateLookups();
  }
}
